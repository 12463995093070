import payload_plugin_dBP4jO2PtF from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@11.11.1_firebase-functions@_2b5710432b3e4006950f9797c06e07c1/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_R2qnvjsIQg from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_1429e4d09db37f26dc1d5f3457d4878e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_n1x0XafwPg from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_1429e4d09db37f26dc1d5f3457d4878e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lUHslPhrXx from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_1429e4d09db37f26dc1d5f3457d4878e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0vPF3Clr7j from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.32.0_vite@4.5.9_@types+node@22.10.10_les_181c8217e27b2656e410571ef3030661/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_jxRDualbdi from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_1429e4d09db37f26dc1d5f3457d4878e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_914V4Wji4C from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_1429e4d09db37f26dc1d5f3457d4878e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GNSThGBFdd from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_1429e4d09db37f26dc1d5f3457d4878e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_v9KR8zTbyc from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_1429e4d09db37f26dc1d5f3457d4878e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/refresh/.nuxt/components.plugin.mjs";
import prefetch_client_MoNUmQbJSR from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_1429e4d09db37f26dc1d5f3457d4878e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_MgEkhWLaIX from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.5.4___magica_b83cf8f6e5bc575648a4768b611cbd51/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_RjMhS9nC1M from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.1.0_magicast@0.3.5_rollup@4.32.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_w4F8UTCVr2 from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@11.11.1_firebase-functions@_2b5710432b3e4006950f9797c06e07c1/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_plugin_8cq4FnzXKb from "/opt/render/project/src/apps/refresh/.nuxt/vuefire-plugin.mjs";
import contentful_bOzdIRmqaX from "/opt/render/project/src/apps/refresh/plugins/contentful.js";
import rollbar_XnPIYyOzed from "/opt/render/project/src/apps/refresh/plugins/rollbar.ts";
import socialShare_gA6PfhsR4E from "/opt/render/project/src/apps/refresh/plugins/socialShare.js";
export default [
  payload_plugin_dBP4jO2PtF,
  revive_payload_client_R2qnvjsIQg,
  unhead_n1x0XafwPg,
  router_lUHslPhrXx,
  _0_siteConfig_0vPF3Clr7j,
  payload_client_jxRDualbdi,
  navigation_repaint_client_914V4Wji4C,
  check_outdated_build_client_GNSThGBFdd,
  chunk_reload_client_v9KR8zTbyc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MoNUmQbJSR,
  plugin_OHU0vggoM9,
  plugin_MgEkhWLaIX,
  i18n_RjMhS9nC1M,
  plugin_client_w4F8UTCVr2,
  vuefire_plugin_8cq4FnzXKb,
  contentful_bOzdIRmqaX,
  rollbar_XnPIYyOzed,
  socialShare_gA6PfhsR4E
]