import routerOptions0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqli_1429e4d09db37f26dc1d5f3457d4878e/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/render/project/src/apps/refresh/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}